.customTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;

    color: #FFFFFF;
}
.customText {
    font-weight: bold;
    font-size: 18px;
    line-height: 198.02%;

    text-align: justify;

    color: #00349C;

    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .customTitle {
        font-style: normal;
        font-size: 18px;
    }

    .customText {
        font-size: 14px;
    }
}