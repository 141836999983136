.basicInformationLabel {
  width: 734px;
  height: 91px;
  margin: 0 auto;

  background: #00349c;
  border-radius: 20px 20px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.detailsLabel {
  max-width: 308px;
  height: 91px;
  margin: 0 auto;

  background: #00349c;
  border-radius: 20px 20px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  max-width: 1346px;

  background: #fff;
  border-radius: 20px;

  margin-bottom: 50px;
}

.formStep {
  background: #f2f7ff;
  border-radius: 20px 20px 0 0;
  padding: 20px 71px;
}

.stepBtn {
  background: #f2f7ff;
  padding-bottom: 46px;
  padding-left: 71px;
  border-radius: 0 0 20px 20px;

  display: flex;
  gap: 10px
}

.inputWrapper {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/

  gap: 26px;

  margin-bottom: 35px;
}

.inputTitle {
  width: 137px;
}

.formWrapper1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
  gap: 26px;
}
.inputForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.customSelect {
  padding: 1px 15px;

  border: 0.5px solid #00349c;
  border-radius: 10px;
}

.proceedButton {
  width: 223px;
  height: 70px;

  background: #00349c;
  border: 0px solid #ffffff;
  border-radius: 15px;

  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;

  color: #ffffff;

  cursor: pointer;
  transition: transform 0.3s ease;
}

.proceedButton:hover {
  transform: scale(1.05);
}

.submitButton {
  width: 223px;
  height: 70px;

  background: #039700;
  border: 0px solid #ffffff;
  border-radius: 15px;

  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;

  color: #ffffff;

  cursor: pointer;
  transition: transform 0.3s ease;
}

.submitButton:hover {
  transform: scale(1.05);
}

.browseContainer {
  margin: 33px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 50px;
}

.browseInput {
  width: 447px;
  height: 447px;

  background: #f6f9ff;
  border: 2px dashed #7e7373;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textWrapper {
  max-width: 160px;
}

.browseLink {
  color: #00349c;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;

  padding-left: 5px;
}

.btnLisensya {
  width: 131px;
  height: 40px;

  border: none;
  margin-top: 21px;

  background: #00349c;
  border-radius: 8.81166px;

  cursor: pointer;
  transition: transform 0.3s ease;
}

.btnLisensya:hover {
  transform: scale(1.05);
}

.mantine-Stepper-separator {
  background-color: transparent !important;
}

.mantine-Stepper-separatorActive {
  background-color: transparent !important;
}

.autoCompleteInput {
  width: 100%;
  border: 0.5px solid #00349c;
  border-radius: 10px;

  padding: 1px 15px;

  background-color: transparent !important;
  color: transparent;
}

.fileImage {
  width: 50px;
}

@media screen and (min-width: 1367px) {
  .fileImage {
    width: 50px;
  }
}

@media (max-width: 768px) {
  .basicInformationLabel {
    width: auto;
    height: auto;
    text-align: center;
  }

  .formStep {
    padding: 10px;
  }

  .inputTitle {
    width: 100%;
  }

  .formWrapper1 {
    display: flex;
    flex-direction: column;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    gap: 13px;
  }

  .browseInput {
    width: 100%;
    height: 247px;
  }

  .detailsLabel {
    width: 100%;
    height: 100%;
    max-width: 208px;
  }

  .stepBtn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom: 20px;
    padding-left: 0;
  }

  .routeFlex {
    padding-bottom: 20px;
  }

  .submitButton {
    height: 45px;
    font-size: 18px;
  }

  .proceedButton {
    height: 45px;
    font-size: 18px;
  }

  .fileImage {
    width: 50px;
  }
}
