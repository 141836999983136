.footer {
  margin-top:5rem;
  width: 100%;
}
.footerWrapper {
  padding: 20px;
  position: relative;
  bottom: 0;
  background: #f2f2f2;
  height: 100%;
}

.footerContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerContainerLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: #1E1E1E;
}

.footerContainerRight {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 56px;
}

.apply {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  gap: 10px;
  z-index: 1;
}

.imagePh {
  position: absolute;
  top: 0;
  right: 0;

  z-index: 0;
}

@media (max-width: 768px) {
  .footerContainer {
    padding: 5px;
    display: flex;
    flex-direction: column-reverse;
    /*justify-content: space-between;*/
    align-items: center;
  }
  .footerContainerRight {
    margin-bottom: 10px;
  }
}
