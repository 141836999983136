.customInput {
    width: 100%;
    border: 0.5px solid #00349C;
    border-radius: 10px;

    padding: 1px 15px;

    background-color: transparent !important;
    color: transparent;
}

@media (max-width: 768px) {
    .customInput {

    }
}