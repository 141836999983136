.mantine-Modal-header {
  padding: 18px 20px;
}

.modalImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subTitleWrapper {
  margin: 25px 0 10px;
  text-align: center;
  /*max-width: 280px;*/
}

.okButton {
  width: 461px;
  height: 70px;
  margin-top: 20px;

  border: none;
  background: #00349c;
  box-shadow: inset 0px 0px 10px 5px #00349c;
  border-radius: 15px;

  text-transform: uppercase;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.okButton:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.noButton {
  width: 461px;
  height: 70px;
  margin-top: 20px;

  background: transparent;

  border: 1px solid #fe0000;
  /*box-shadow: inset 0px 0px 10px 5px #B50000;*/
  border-radius: 15px;

  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  box-sizing: border-box;

  text-transform: uppercase;
}

.noButton:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.modalButton {
    width: 100%;
    display: flex;
  align-items: center;
    flex-direction: column;
}

@media (max-width: 768px) {
  .okButton {
    width: 100%;
    height: 45px;
  }
  .noButton {
    width: 100%;
    height: 45px;
  }
}
