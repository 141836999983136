

.contactUsWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 150px;

}

.flexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 335px;

  gap: 13px;
}

.roundedContainer {
  width: 140px;
  height: 140px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 52, 156, 0.2);
}

@media (max-width: 768px) {
  .roundedContainer {
    width: 80px;
    height: 80px;
  }
}


