.homepage {
  height: 100vh;
  background-image: url("assets/png/strike-bg.png"); /* Replace with your image file path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.homeContainer {
  height: 100vh;
}

.navWrapper {
  height: 116px;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  gap: 30px;
}

.navLink {
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
}

.applyBtn {
  width: 223px;
  height: 70px;

  margin-top: 14px;

  background: #fe0000;
  border: 2px solid #ffffff;
  box-shadow: inset 0px 0px 10px 5px #b50000;
  border-radius: 15px;

  cursor: pointer;
  transition: transform 0.3s ease;
}

.applyBtn:hover {
  transform: scale(1.05);
}

.welcomeTitle {
  font-size: 100px
}

.appTitle {
font-size: 64px
}

.preRegisterTitle {
  font-size: 45px;
}

.subTitle {
font-size: 25px;
}

@media screen and (min-width: 1367px) {
  /* CSS styles for large laptops */
  .welcomeTitle {
    font-size: 80px
  }

  .appTitle {
font-size: 52px;
  }

  .preRegisterTitle {
    font-size: 35px;
  }

  .subTitle {
    padding-top: 45px;
font-size: 21px;
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* CSS styles for small laptops */
}

@media screen and (max-width: 768px) {
  .applyBtn {
    width: 150px;
    height: 50px;
  }

  .welcomeTitle {
    font-size: 50px
  }

  .appTitle {
    font-size: 32px;
  }

  .preRegisterTitle {
    font-size: 23px;
  }

  .subTitle {
    font-size: 18px;
  }


}
